import React, { useEffect, useState } from 'react';
import { Billing, ResponseResult } from '../types';
import { Form, Button, Input} from 'antd';
import Swal from 'sweetalert2';
import { request } from '../services/Request';

function BillingNew({callback, userId}:{callback:() => void, userId:string}){

    const [billing, setBilling] = useState<Partial<Billing>>({})

    const onSubmit = (values:Billing) => {
        Swal.fire("Facturación", "Enviando eptición...");
        Swal.showLoading();
        let data:any = values;
        data['id'] = userId;
        request("/api/student/billing",{
            method: 'POST',
            body: JSON.stringify(data)
        })
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                callback();
            }else{
                Swal.fire("Facturación", "Ocurrió un error al guardar el dato", "error");
            }
        })
    } 

    const onFinishFailed = (e:unknown) => {
        Swal.fire("Facturación", "Es necesario completar todos los campos");
    } 

    return (
        <div className="mt-6">
            <h3>Nuevo dato de facturación</h3>
            <Form onFinish={onSubmit} onFinishFailed={onFinishFailed}>
                    <Form.Item label="Nombre" name="name" rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingresa un nombre o razón social',
                                },
                            ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="RFC" name="rfc" rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingresa la RFC',
                                },
                            ]}>
                        <Input  />
                    </Form.Item>
                    <Form.Item label="Código Postal" name="postal_code" rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingresa el código postal',
                                },
                            ]}>
                        <Input  />
                    </Form.Item>
                    <Form.Item label="Dirección" name="address">
                        <Input required />
                    </Form.Item>
                    <Form.Item label="Régimen Fiscal" name="fiscal_regime" rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingresa el régimen fiscal',
                                },
                            ]}>
                        <Input  />
                    </Form.Item>
                    <Form.Item label="CFDI" name="cfdi" rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingresa el CFDI',
                                },
                            ]}>
                        <Input  />
                    </Form.Item>
                <Button type="primary" htmlType="submit">
                    Guardar
                </Button>
            </Form>
        </div>
    )

}

export default BillingNew