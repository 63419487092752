import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./pages/auth/Login";
import Profile from "./pages/auth/Profile";
import StatusStudent from "./pages/account/StatusStudent";
import "./App.scss";
import ProtectedRoute from "./services/ProtectedRoute";

import { getConfig } from "./services/Config.service";
import ConfigContext, { initialConfig } from "./context/Config";

function App() {
  const [config, setConfig] = useState({ loading: true, data: initialConfig });
  const { loading, data } = config;

  useEffect(() => {
    async function getConfigAsync() {
      const { data } = await getConfig();
      setConfig({ loading: false, data });
    }
    getConfigAsync();
  }, []);

  return (
    <div className="App">
      {loading && <p>Cargando aplicación</p>}
      {data.error && <p>'Error getting config from server'</p>}
      <ConfigContext.Provider value={data}>
        <Header></Header>
        <Container fluid className="p-0 mt-5">
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <ProtectedRoute path="/accountstatus">
                <StatusStudent></StatusStudent>
              </ProtectedRoute>
              <ProtectedRoute path="/profile">
                <Profile></Profile>
              </ProtectedRoute>
              <Route path="/login">
                <Login></Login>
              </Route>
              <Route path="/">
                <Login></Login>
              </Route>
            </Switch>
          </Router>
        </Container>
        <Footer></Footer>
      </ConfigContext.Provider>
    </div>
  );
}

export default App;
