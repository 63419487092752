import React, {useState,useEffect} from 'react';
import '../styles/header.scss';
import { request } from  '../services/Request';
import { ResponseResult } from '../types';
import { Menu } from 'antd';
import { CreditCardOutlined, UserOutlined } from '@ant-design/icons';

function Header(){

    const [isLogin, setIsLogin] = useState(false);
    const [current, setCurrent] = useState('');

    useEffect(()=>{
        request('/api/auth/me',{
            noRedirect:true
        })
        .then((result:ResponseResult)=>{
            if(result.data){
                setIsLogin(true);
            }
        })
    },[]);

    const handleClick = (e:any) => {
        setCurrent(e.key);
        window.location.href= e.key;
    };

    return (
        <div id="header">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
                {
                    isLogin && (
                        <React.Fragment>
                            <Menu.Item key="account_status" icon={<CreditCardOutlined />}>
                                <a href={process.env.PUBLIC_URL + "/accountstatus"}>Estado de cuenta</a>
                            </Menu.Item>
                            <Menu.Item key="profile" icon={<UserOutlined />}>
                                <a href={process.env.PUBLIC_URL + "/profile"}>Perfil</a>
                            </Menu.Item>
                        </React.Fragment>
                    )
                }
            </Menu>
        </div>
    )
}

export default Header;