import React, {useState, useContext} from 'react';
import { Card, Button, Space, Row, Col, Alert } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { ResponseResult } from '../../types';
import GoogleLogin from 'react-google-login';
import ConfigContext from '../../context/Config';

function Login(){
    const { page:config } = useContext(ConfigContext);
    const colorText = config.login_card_color == '#FFF' ? '#000' : '#fff' ;

    const responseGoogle = (response:any) => {
        if(response && response.profileObj){
            Swal.fire("Cargando","Por favor espere");
            Swal.showLoading();
            request('/api/student/signin/'+response.profileObj.email,{})
            .then((result:ResponseResult)=>{
                if(result.data){
                    localStorage.token = result.data.token;
                    Swal.close();
                    window.location.href=process.env.PUBLIC_URL+"/accountstatus";
                }else{
                    Swal.close();
                    Swal.fire("Error", result.error.message, "error");
                }
            })
        }
    }

    return (
        <div id="login">
            <Row>
                <Col xs={{span: 24}} md={{span:22,offset:1}}>
                    <Card className="login-style" style={{backgroundColor:config.login_card_color}}>                
                    <h4 style={{color: colorText}} className="mb-5">Bienvenido a la plataforma de pagos para alumnos de</h4>
                        <img src={config.logo} className="col-md-6 col-12"></img>
                        <br></br>
                        <div style={{color: colorText}} className="mt-5 mb-5">
                            Para ingresar es necesario iniciar sesión con tu cuenta como alumno
                        </div>  
                        {
                            config.support_email !== '' && (
                                <Alert className="mt-5 mb-5" message={<p>Si estas teniendo problemas para ingresar, generar o descargar referencias, favor de ponerse en contacto con el departamento de informática: <a href={"mailto:"+config.support_email}>{config.support_email}</a></p>} type="warning" />                            
                            )
                        }
                        <GoogleLogin
                            clientId={process.env.REACT_APP_CLIENT_GOOGLE || ''}
                            render={renderProps => (
                                <Button onClick={renderProps.onClick} disabled={renderProps.disabled} type="primary">Iniciar sesión</Button>
                                )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                        />                    
                    </Card>

                </Col>
            </Row>                                      
        </div>
    )
}

export default Login;