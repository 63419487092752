import { createContext } from 'react';

export const initialConfig = {
  name: "No name",
  components: {
    students: {
      payment_button_enabled: true,
      show_amount_to_pay: true,
      payment_type: 'referencia',
      career_enabled: false,
      uma_value: 1,
      iva_value: 16
    },
    cash_payments:{
      account_enabled: false,
      conekta_enabled: false
    }
  },
  page: {
    logo: '',
    login_card_color: '#FFF',
    support_email: ''
  },
  error: null,
}

const ConfigContext = createContext(initialConfig);

export default ConfigContext;