import React, {useState,useEffect} from 'react';
import { Card, Button, Row, Col } from 'antd';
import { request } from '../../services/Request';
import Swal from 'sweetalert2';
import { ResponseResult } from '../../types';
import { Student, Billing } from '../../types';
import BillingNew from '../../components/BillingNew';

function Profile(){

    const [user, setUser] = useState<Partial<Student>>({});
    const [showNew, setNew] = useState(false);
    
    useEffect(()=>{
        getUser();
    },[]);

    const getUser = () => {
        Swal.fire("Usuario", "Cargando...");
        Swal.showLoading();
        request("/api/auth/me",{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                request("/api/student/"+result.data._id,{})
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        setUser(result.data);
                    }
                })   
            }
        })
    }

    const confirmRemove = (billing:Billing) => {
        Swal.fire({
            title: 'Facturación',
            text: '¿Estás seguro de eliminar este dato de facturación?',
            showCancelButton:true,
            cancelButtonText: 'No',
            confirmButtonText: 'Sí'
        }).then((result)=>{
            if(result.isConfirmed){
                request("/api/student/"+user._id+"/billing/"+billing._id,{
                    method:'DELETE'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getUser();
                    }
                })
            }
        })
    }

    return (
        <div id="profile">
            <Row>
                <Col xs={{span: 24}} md={{span:16, offset:4}}>
                    <Card title={"Perfil"}>                
                        <p><b>Nombre: </b> { user.name+" "+user.first_lastname+" "+user.second_lastname }</p>
                        <p><b>Matrícula: </b> { user.student_id }</p>
                        <p className="mt-10"><b>Datos de facturación</b></p>
                        {
                            user.billing_options && user.billing_options.map((billing:Billing, key:number)=>(
                                <div className="billing mb-5">
                                    <hr/>
                                    <Row>
                                        <Col xs={24} md={12}>
                                            <p><b>Nombre: </b> { billing.name }</p>
                                            <p><b>RFC: </b> { billing.rfc }</p>
                                            <p><b>Código Postal: </b> { billing.postal_code }</p>
                                            <Button type="default" onClick={()=>confirmRemove(billing)}>Eliminar</Button>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <p><b>Régimen fiscal: </b> { billing.fiscal_regime }</p>
                                            <p><b>CFDI: </b> { billing.cfdi }</p>
                                            <p><b>Dirección: </b> { billing.address }</p>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        }
                        {
                            !user.billing_options || user.billing_options.length == 0 && (
                                <p>No tiene datos de facturación.</p>
                            )
                        }
                        <Button type="primary" onClick={()=> setNew(true)}>Agregar nuevo dato</Button>
                        {
                            showNew && (
                                <BillingNew userId={user._id || ''} callback={() =>{ setNew(false); getUser();}}></BillingNew>
                            )
                        }
                    </Card>
                </Col>
            </Row>                                      
        </div>
    )
}

export default Profile;