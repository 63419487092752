import {Row, Col} from 'react-bootstrap';
import '../styles/footer.scss';

function Footer(){
    return (
        <div id="footer">
        </div>
    );
}

export default Footer;