import React, { useState } from 'react';
import {Button, Form, Card, message, Select, Input, Row, Col, AutoComplete } from "antd";
import Swal from 'sweetalert2';
import { ResponseResult, Concept, Subject } from '../types';
import { request } from '../services/Request';

interface Props {
    IDstudent? : string;
    academicConcepts: Concept[];
    cycleId: string;
    subjects: Subject[];
}

function ConceptNewStudent(props : Props){
    const { IDstudent, academicConcepts, subjects, cycleId } = props;
    const { Option } = Select;
    const [selectedSubject, setSelectedSubject] = useState<Partial<Subject>>({});
    const [selectedConcept, setSelectedConcept] = useState<Partial<Concept>>({});
    const [ disabledSubmitButton, setDisabledSubmitButton ] = useState(false)

    const onSubmit = (values: Concept) => {
        setDisabledSubmitButton(true);
        Swal.fire("Nuevo concepto", "Guardando concepto...");
        Swal.showLoading();
        console.log(values);
        if(values.amount && (values.amount < selectedConcept.manual_min || values.amount > selectedConcept.manual_max)){
            Swal.fire("Nuevo Concepto", "La cantidad ingresada no coincide con el mínimo y máximo del concepto seleccionado", "error")
        }else{
            const data:any = values;
            data['student'] = IDstudent;
            data['title'] = selectedConcept.title;
            data['cycle'] = cycleId;
            request('/api/academicconcept/extra',{
                method: 'POST',
                body: JSON.stringify(data)
            })
            .then((result:ResponseResult)=>{
                Swal.close();
                if(result.data){
                    window.location.reload();
                }else{
                    Swal.fire("Nuevo concepto", "Ocurrió un error al agregar el concepto");
                }
            })
        }
    }

    const onFinishFailed = (errorInfo:unknown) => {
        message.error('Es necesario completar todos los campos');
    };
    const onSelect = (e:string) => {
        const subject = subjects.filter((item:Subject)=>{
            return item.name === e;
        });
        setSelectedSubject({...subject[0]});
    }

    const onSelectType = (e:string) => {
        const concept = academicConcepts.filter((item:Concept)=>{
            return item._id === e;
        });
        setSelectedConcept({...concept[0]});
    }

    const onChangeAmount = (e:React.ChangeEvent<HTMLInputElement>) => {

    }

    return (
        <div className="new-heading mt-5">
            <Card title="Agregar concepto de pago">
                    <Form onFinish={onSubmit} onFinishFailed={onFinishFailed}>
                        <Form.Item label="Concepto:" className="mb-3" name="concept" rules={[
                                {
                                    required: true,
                                    message: 'Selecciona el tipo de concepto',
                                },
                            ]}>
                            <Select className="concept" placeholder="" onChange={(e:string)=>onSelectType(e)} showSearch
                                optionFilterProp="children" defaultValue={"null"}>
                                <Option value="null">Seleccionar concepto</Option>
                                {
                                    academicConcepts.map((concept:Concept, key:number)=>(
                                        <Option value={concept._id} key={key}>{concept.title}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item className="mt-3" id={"quantity"} name="quantity"
                            label={`Ingrese la cantidad de conceptos:`}>
                            <Input type="number" name="quantity" defaultValue="1"></Input>
                        </Form.Item>
                        {
                            selectedConcept && selectedConcept.manual && (
                                <React.Fragment>
                                    <Form.Item className="mt-3" id={"amount"} name="amount"
                                        label={`Ingrese la cantidad a cobrar (Min: ${selectedConcept.manual_min.toFixed(2)} / Max: ${selectedConcept.manual_max.toFixed(2)})`}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Ingrese la cantidad',
                                            },
                                        ]}>
                                        <Input type="number" name="amount" onChange={(e:any) => onChangeAmount(e)}></Input>
                                    </Form.Item>
                                </React.Fragment>
                            )
                        }
                        {
                            selectedConcept && selectedConcept.subject && (
                                <Form.Item label="Materia relacionada:" className="mb-3" name="subject" rules={[
                                        {
                                            required: true,
                                            message: 'Selecciona la materia releccionada',
                                        },
                                    ]}>
                                    <Select placeholder="" onChange={onSelect} showSearch
                                    optionFilterProp="children">
                                        <Option value="null">Seleccionar materia</Option>
                                        {
                                            subjects.map((subject:Subject, key:number)=>(
                                                <Option value={subject._id} key={key}>{subject.name}</Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            )
                        }
                        <div className="d-flex">
                            <Button type="primary" disabled={disabledSubmitButton} htmlType="submit">
                                Agregar concepto
                            </Button>
                        </div>
                    </Form>
            </Card>
        </div>
    );
}

export default ConceptNewStudent;