import React, {useContext, useEffect,useState} from 'react';
import { ResponseResult, SchoolCycle, Status, Payment, Concept, Subject, AcademicConcept } from '../../types';
import { Row, Col, Card, Table, Form, Button, Modal, Space, Checkbox, Input, Radio } from 'antd';
import { request } from '../../services/Request';
import { format, differenceInDays } from 'date-fns';
import Swal from 'sweetalert2';
import ConfigContext from '../../context/Config';
import commaSeparator from '../../services/CommaSeparator';
import ConceptNewStudent from '../../components/ConceptNewStudent';
import * as Sentry from "@sentry/react";

function StatusStudent(){

    const [status, setStatus] = useState<Status[]>([]);
    const [previousStatus, setPreviousStatus] = useState<Status[]>([]);
    const [otherPayments, setOtherPayments] = useState<Status[]>([]);
    const [student, setStudent] = useState({
        _id: '',
        name: '',
        first_lastname: '',
        second_lastname: '',
        student_id: '',
        status
    });
    const [selectedConcepts, setSelectedConcepts]:any = useState([]);
    const [selectedOtherConcepts, setSelectedOtherConcepts]:any = useState([]);
    const [selectedList, setSelectedList]:any = useState([]);
    const [payWindow, setPayWindow] = useState(false);
    const [amountToPay, setAmountToPay] = useState(0);
    const [selectedConcept, setSelectedConcept]= useState<Partial<Status>>({
        payments:[]
    });
    const [paymentsWindow, setPaymentsWindow] = useState(false);
    const [selectedConceptIds, setSelectedConceptIds] = useState<string[]>([]);

    const [selectedOtherConceptIds, setSelectedOtherConceptIds] = useState<string[]>([]);
    const { components: { students: config, cash_payments: configPayment} } = useContext(ConfigContext);
    const [selectedType, setType] = useState('');
    const [selectedCycle, setSelectedCycle] = useState<Partial<SchoolCycle>>({
        name:''
    });

    const [newConceptStudent, setNewConceptStudent] = useState(false);
    const [academicConcepts, setAcademicConcepts] = useState<Concept[]>([]);
    const [subjects, setSubjects] = useState<Subject[]>([]);

    useEffect(()=>{
        getStudent();
        getCycles();
        getAcademicConcepts();
        getSubjects();
    }, []);
    
    const getStudent = async () => {
        Swal.fire("Cargando", "Cargando la información del alumno...");
        Swal.showLoading();
        const result = await request('/api/auth/me',{})
        Swal.close();
        if(result.data){
            const student_result = await request("/api/student/"+result.data._id,{})
            Swal.close();
            if(student_result.data){
                setStudent(student_result.data);
            }
            getStatus();
        }
}

    const getStatus = async () => {
        Swal.fire("Cargando", "Cargando la información del alumno...");
        Swal.showLoading();
        const result = await request('/api/payment/obtainstatus',{})
        Swal.close();
        if(result.data && result.data.current){
            setStatus(result.data.current);
            setPreviousStatus(result.data.previous);
            setOtherPayments(result.data.other);
        } else {
            Swal.fire("Estado de cuenta", "No podemos cargar su esgado de cuenta", "error");
        }
    }

    const getCycles = () => {
        try {
            request('/api/cycle',{})
            .then((result:ResponseResult)=>{
                if(result.data){
                    for(const cycle of result.data){
                        if(cycle.actual_cycle){
                            setSelectedCycle(cycle);
                        }
                    }
                }
            })
        } catch (err: unknown){
            Sentry.addBreadcrumb({message: (err as Error).message});
            Sentry.captureException({exception: err});
        }
    }

    const onChangeConcepts = (ids:string[], record:any, index:number) => {
        setSelectedOtherConcepts([]);
        setSelectedOtherConceptIds([]);
        const flag = ids.length > 0 ? true : false;
        let selected:any = [];
        const element = status.filter((item:any)=>{
            return item.concept._id === record.concept._id
        });
        const previous_element = previousStatus.filter((item:any)=>{
            return item.concept._id === record.concept._id
        });
        if(element.length > 0){
            if(previousStatus.length > 0){
                selected = selected.concat( selectCheckboxes(element[0], previousStatus, flag, previousStatus.length + 1) );
                selected = selected.concat( selectCheckboxes(element[0], status, flag, index) );
            }else{
                selected = selected.concat( selectCheckboxes(element[0], status, flag, index) );
            }
        }else if(previous_element.length > 0){
            selected = selected.concat(selectCheckboxes(previous_element[0], previousStatus, flag, index) );
            deselectAll(status);
        }
        let selectedIds = selected.map((item:any)=>{
            return item._id;
        });
        setSelectedConceptIds(selectedIds);
        setSelectedConcepts(selected);
    }

    const onChangeOtherConcepts = (_e:any, _key:number, record: Status)=>{
        deselectAll(status);
        deselectAll(previousStatus);
        setSelectedConceptIds([]);
        setSelectedConcepts([]);
        const selected:any = selectedOtherConcepts.map((payment: Status)=>{
            return payment._id;
        })
        if(selected.includes(record._id)){
            selected.splice(selected.indexOf(record._id), 1);
        } else {
            selected.push(record._id);
        }
        const selectedItems = otherPayments.filter((payment: Status)=>{
            return selected.includes(payment._id);
        })
        const selectedItemsIds = otherPayments.filter((payment: Status)=>{
            return selected.includes(payment._id);
        }).map((payment:Status)=>{
            return payment._id;
        })
        setSelectedOtherConceptIds(selectedItemsIds);
        setSelectedOtherConcepts(selectedItems);
    }

    const selectCheckboxes = (selected:any, payments: any, flag: boolean, key:number) => {
        const selected_ids = [];
        for(let i = 0; i < payments.length; i++){
            const element = document.getElementsByName('concept-'+payments[i]._id) as NodeListOf<HTMLInputElement>;
            if( key >= i){
                if(flag || selected._id !== payments[i]._id){
                    if(element[1]){
                        element[1].checked = true;
                        selected_ids.push(payments[i]);
                    }
                }
            }else{
                if(element && element[1]){
                    element[1].checked = false;
                }
            }
        }
        return selected_ids;
    }

    const deselectAll = (payments: any) => {
        for(let i = 0; i < payments.length; i++){
            const element = document.getElementsByName('concept-'+payments[i]._id) as NodeListOf<HTMLInputElement>;
            if(element && element[1]){
                element[1].checked = false;
            }
        }
    }

    const showConcepts = () => {
        if(selectedConcepts.length > 0 || selectedOtherConcepts.length > 0){
            let concepts:any = [];
            concepts = concepts.concat( selectedConcepts );
            concepts = concepts.concat( selectedOtherConcepts );
            setSelectedList(concepts);
            let total = 0;
            for(const payment of concepts){
                total += payment.total_amount + payment.surcharges_amount - payment.subtotal - payment.discounts_amount;
            }
            setAmountToPay(total);
            setPayWindow(true);
        }else{
            Swal.fire("Pagar", "Es necesario seleccionar al menos un concepto", "error");
        }
    }

    const onChangeAmount = (e:React.ChangeEvent<HTMLInputElement>) => {
        let amount = Number(e.target.value);
        setAmountToPay(amount);
    }

    const onChangeTypePayment = (e:any) => {
        console.log(e.target.value);
        setType(e.target.value);
    }

    const payConcepts = () => {
        let flag = false;
        selectedList.forEach((item:any)=>{
            const difference = differenceInDays( new Date(item.limit_date), new Date() );
            if( difference < 7 && difference >= 0 ){
                flag = true;
            }
        });
        let total = getSumToTal();
        if(config.show_amount_to_pay === true){
            if(amountToPay <= 0){
                Swal.fire("Pagar", "Se debe de pagar una cantidad mayor a 0.", "error")
            } else if(amountToPay > total){
                Swal.fire("Pagar", "No puedes pagar una cantidad mayor al total de los conceptos seleccionados", "error")
            } else {
                if(selectedType == '' || !selectedType){
                    Swal.fire("Pagar", "Es necesario escoger un método de pago", "error");
                }else{
                    if(flag){
                        Swal.fire({
                            title: "Pagar",
                            icon: 'warning',
                            html: "Al menos uno de los conceptos seleccionados tiene fecha límite en menos de una semana, si se realiza el pago de esta cantidad después de dicha fecha, el recargo se seguirá generando en el sistema.<br/>",
                        })
                        .then((result:any)=>{
                            Swal.fire("Pagar", "Enviando correo de pago...");
                            Swal.showLoading();
                            confirmPayment();
                        })
                    }else{
                        Swal.fire("Pagar", "Enviando correo de pago...");
                        Swal.showLoading();
                        confirmPayment();
                    }
                }
            }
        }else{
            if(selectedType == '' || !selectedType){
                Swal.fire("Pagar", "Es necesario escoger un método de pago", "error");
            }else{
                if(flag){
                    Swal.fire({
                        title: "Pagar", 
                        icon: 'warning',
                        html: "Al menos uno de los conceptos seleccionados tiene fecha límite en menos de una semana, si se realiza el pago de esta cantidad después de dicha fecha, el recargo se seguirá generando en el sistema.<br/>",
                    })
                    .then((result:any)=>{
                        Swal.fire("Pagar", "Enviando correo de pago...");
                        Swal.showLoading();
                        confirmPayment();
                    })
                    
                }else{
                    Swal.fire("Pagar", "Enviando correo de pago...");
                    Swal.showLoading();
                    confirmPayment();
                }
            }
        }
    }

    const confirmPayment = () => {
        Swal.fire("Pagar", "Generando recibo...")
        Swal.showLoading();
        request('/api/payment/student',{
            method: 'POST',
            body: JSON.stringify({
                concepts: selectedList,
                amount: amountToPay,
                type: selectedType
            })
        })
        .then(async (result:ResponseResult)=>{
            Swal.hideLoading();
            if(result.data){
                setSelectedConcepts([])
                setSelectedOtherConcepts([])
                setSelectedList([])
                if(selectedType === 'oxxo' || selectedType === 'card/spei'){
                    Swal.fire({title: 'Pagar', text:'Se ha enviado un correo a tu cuenta para continuar con el proceso.', icon: 'success'})
                    .then(()=>{
                        window.location.reload();
                    });
                    setPayWindow(false);
                }else{
                    Swal.fire("Pagar", "Se ha generado tu recibo de pago.", "success");
                    
                    const buffer = await base64ToArrayBuffer(result.data.receipt)
                    var blob = new Blob([buffer], {type: "application/pdf"});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = `recibo_pago_${result.data.id}`;
                    link.download = fileName;
                    link.click();
                }
            }else{
                Swal.fire("Pagar", "Ocurrió un error al enviar el correo de pago.", "error");
            }
        })
    }

    function base64ToArrayBuffer(base64:any) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    const getSumToTal = () => {
        let total = 0;
        for(const payment of selectedList){
            total += payment.total_amount + payment.surcharges_amount - payment.subtotal - payment.discounts_amount;
        }
        return total;
    }

    const ownPayments = (id:string) => {
        const allPayments = previousStatus.concat(status).concat(otherPayments);
        const concept = allPayments.filter((item:any)=>{
            return item._id === id
        });
        setSelectedConcept(concept[0]);
        setPaymentsWindow(true);
    }

    const columns = [
        {
            title: 'Concepto',
            render: (text:string, record:Status, index:number) => (
                <React.Fragment>
                    { record.subject ? record.concept.title+" - "+record.subject.name  : record.concept.title} { record.quantity && record.quantity > 1 ? "x"+record.quantity : "" }
                </React.Fragment>
            )
        },
        {
            title: 'F.Vence',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    { record.limit_date ? format(new Date(record.limit_date), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'F.Ultimo pago',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                    record.payments.length > 0 && record.payments[ record.payments.length - 1 ].paid_at ? (
                        <span>{format(new Date(record.payments[ record.payments.length - 1 ].paid_at), 'dd/MM/yyyy')}</span>
                    ) : (
                        <span>Pendiente</span>
                    )
                    }
                </Space>
            )
        },
        {
            title: 'Importe',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.total_amount.toFixed(2)) }
                </div>
            )
        },
        {
            title: 'Recargos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.surcharges_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Descuentos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.discounts_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Pagado',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.subtotal.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Total',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(((record.total_amount + record.surcharges_amount) - record.subtotal - record.discounts_amount).toFixed(2)) }
                </div>
            )
        },
        {
            title: 'Pagar',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    {
                        (!record.status_complete_paid && !record.disabled && config.payment_button_enabled) && (
                            <Checkbox.Group options={[{label: '', value: record._id}]}  onChange={(e: any )=>onChangeConcepts(e, record, index)} value={selectedConceptIds} name={"concept-"+record._id}/>
                        )
                    }
                </div>
            )
        },
        {
            title: 'Registro de pagos',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    <Button type="primary" onClick={()=>{ownPayments(record._id)}}>Ver pagos</Button>
                </Space>
            )
        }
    ];

    const columnsOther:any = [
        {
            title: 'Concepto',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    { record.subject ? record.concept.title+" - "+record.subject.name  : record.concept.title} { record.quantity && record.quantity > 1 ? "x"+record.quantity : "" } { record.concept.is_variable ? <sup title="El monto puede estar sujeto a cambios">(*)</sup> : '' }
                </Space>
            )
        },
        {
            title: 'F.Vence',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    { record.limit_date ? format(new Date(record.limit_date), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'F.Ultimo pago',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                    record.payments.length > 0 && record.payments[ record.payments.length - 1 ].paid_at ? (
                        <span>{format(new Date(record.payments[ record.payments.length - 1 ].paid_at), 'dd/MM/yyyy')}</span>
                    ) : (
                        <span>Pendiente</span>
                    )
                    }
                </Space>
            )
        },
        {
            title: 'Importe',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.total_amount.toFixed(2)) }
                </div>
            )
        },
        {
            title: 'Recargos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.surcharges_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Descuentos',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.discounts_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Pagado',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.subtotal.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Total',
            render: (text:string, record:Status, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(((record.total_amount + record.surcharges_amount) - record.subtotal - record.discounts_amount).toFixed(2)) }
                </div>
            )
        },
        {
            title: 'Pagar',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    {
                        (!record.status_complete_paid && !record.disabled && config.payment_button_enabled) && (
                            <Checkbox.Group options={[{label: '', value: record._id}]} value={selectedOtherConceptIds} onChange={(e: any )=>onChangeOtherConcepts(e, index, record)} name={"concept-"+record._id}/>
                        )
                    }
                </Space>
            )
        },
        {
            title: 'Registro de pagos',
            render: (text:string, record:Status, index:number) => (
                <Space>
                    <Button type="primary" onClick={()=>{ownPayments(record._id)}}>Ver pagos</Button>
                </Space>
            )
        }
    ]

    const registeredPaymentsColumns = [
        {
            title: 'Fecha de creación',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {format(new Date(record.created_at), 'dd/MM/yyyy')}
                </Space>
            )
        },
        {
            title: 'Estatus',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.status_paid ? 'Pagado' : 'Por pagar'}
                </Space>
            )
        },
        {
            title: 'Fecha de pago',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.paid_at ? format(new Date(record.paid_at), 'dd/MM/yyyy') : ''}
                </Space>
            )
        },
        {
            title: 'No. recibo',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {record.receipt_number ? record.receipt_number : ''}
                </Space>
            )
        },
        {
            title: 'Cantidad abonada',
            render: (text:string, record:Payment, index:number) => (
                <div className="text-end">
                    $ {commaSeparator(record.paid_amount.toFixed(2))}
                </div>
            )
        },
        {
            title: 'Tipo de pago',
            render: (text:string, record:Payment, index:number) => (
                <div className="text-center">
                    {record.type}
                </div>
            )
        },
        {
            title: 'Detalle',
            render: (text:string, record:Payment, index:number) => (
                <Space>
                    {
                        record.payment_detail && (
                            <Button onClick={()=>{setPaymentsWindow(false)}} type="primary" ghost>
                                Cerrar
                            </Button>
                        )
                    }
                </Space>
            )
        }
    ];

    const hasScholarship = () => {
        let flag = false;
        let disabled = false;
        let scholarship = '';
        for(const concept of status){
            if(concept.discounts){
                for(const discount of concept.discounts){
                    if( discount.scholarship && discount.status ){
                        flag = true;
                        scholarship = discount.reason;
                    }
                    if( discount.scholarship && !discount.status ){
                        disabled = true;
                    }
                }
            }
        }
        return flag ? <p><b>Beca: </b>{scholarship} { disabled ? '(Cancelada)' : '' }</p> : '';
    }

    const checkPriceVariable = ( payments: any) => {
        let flag = false;
        for(const payment of payments){
            if(payment.concept.is_variable){
                flag = true;
            }
        }
        return flag;
    }

    const createConcepts = () => {
        Swal.fire({
            title: 'Crear conceptos',
            html: '¿Estás seguro de generar los conceptos para el ciclo?<br/><br/>Los montos asignados quedan sujetos a modificación según corresponda la carga académica. Además, la asistencia y los cargos de las colegiaturas se contarán desde el primer día de clases, según el calendario académico de la Universidad publicado para el ciclo correspondiente, independientemente de la fecha de reinscripción. ',
            confirmButtonText: 'Sí',
            showConfirmButton: true,
            cancelButtonText: 'No',
            showCancelButton: true
        }).then((result)=>{
            if(result.isConfirmed){
                Swal.fire('Crear conceptos', 'Cargando...');
                Swal.showLoading();
                request('/api/payment/student/create',{
                    method: 'POST'
                })
                .then((result:ResponseResult)=>{
                    Swal.close();
                    if(result.data){
                        getStatus();
                    }else{
                        Swal.fire("Crear conceptos", "Ocurrió un error al generar los conceptos", "error");
                    }
                })
            }
        })
    }

    const showNewConceptStudent = () =>{
        setNewConceptStudent(true);
        setTimeout(()=>{
            const element = document.getElementById("newConceptStudent");
            element?.scrollIntoView({block: "start"}) as unknown as HTMLElement;
            Swal.close();
        },100);

    }

    const getAcademicConcepts = () => {
        Swal.showLoading();
        request('/api/academicconcept/students?price=true',{})
        .then((result:ResponseResult)=>{
            Swal.close();
            if(result.data){
                let concepts = result.data;
                if(config.uma_value > 1){
                    concepts = result.data.map((concept: AcademicConcept) => {
                        if(concept.umas_payment){
                            const currentConcept = {
                                ...concept,
                                manual_min: concept.manual_min ? concept.manual_min * config.uma_value : null,
                                manual_max: concept.manual_max ? concept.manual_max * config.uma_value : null,
                                price: concept.price ? concept.price * config.uma_value : null,
                            }
                            return currentConcept;
                        } else {
                            return concept;
                        }
                    })
                }
                setAcademicConcepts(concepts);
            }
        })
    }
    const getSubjects = () => {
        request('/api/subject',{})
        .then((result:ResponseResult)=>{
            if(result.data){
                setSubjects(result.data);
            }
        })
    }
    return (
        <div>
            <Row>
                <Col xs={24} md={{span:20,offset:2}}>
                    <Card>
                        <h1>Estado de cuenta</h1>
                        <p><b>Nombre: </b>{student.name+" "+student.first_lastname+" "+student.second_lastname}</p> 
                        <p><b>Matrícula: </b>{student.student_id}</p>
                        {
                            hasScholarship()
                        }
                        <p><b>Ciclo actual: </b>{selectedCycle.name}</p>
                        {
                            configPayment.account_enabled && (
                                <Row className="mt-5 mb-5">
                                    <Col xs={24} md={8}>
                                        <Button type="primary" onClick={createConcepts}>Crear conceptos del periodo actual</Button>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            previousStatus.length > 0 && (
                                <Card>
                                    <h3>Conceptos pasados</h3>
                                    <Table pagination={false} columns={columns} dataSource={previousStatus} rowKey="_id"/>
                                </Card>
                            )
                        }
                        {
                            status.length > 0 && (
                                <Card>
                                    <h3>Conceptos en curso</h3>
                                    <Table pagination={false} columns={columns} dataSource={status} rowKey="_id"/>
                                </Card>
                            )
                        }
                        {
                            otherPayments.length > 0 && (
                                <Card>
                                    <h3>Otros conceptos</h3>
                                    <Table pagination={false} columns={columnsOther} dataSource={otherPayments} rowKey="_id"/>
                                    {
                                        checkPriceVariable( otherPayments ) && (
                                            <p className="mt-10">(*) El monto puede estar sujeto a cambios</p>
                                        )
                                    }
                                </Card>
                            )
                        }
                        { (previousStatus.length > 0 || status.length > 0 || otherPayments.length > 0) && config.payment_button_enabled && (
                            <Row>
                                <Col span={4}>
                                    <Button type="primary" onClick={()=>{showConcepts()}}>
                                        Pagar seleccionados
                                    </Button>
                                </Col>
                                <Col span={4}>
                                    <Button type="primary" onClick={showNewConceptStudent}>
                                        Agregar concepto
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Card>
                    {
                        (newConceptStudent) && (
                            <div className="mt-5 mb-10" id="newConceptStudent">
                                <Row>
                                    <Col xs={12} md={{span:16,offset:4}}>
                                        <ConceptNewStudent cycleId={selectedCycle._id || ""} IDstudent={student._id} academicConcepts={academicConcepts} subjects={subjects}></ConceptNewStudent>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                </Col>
            </Row>
            <Modal
            title='Pagar conceptos'
            visible={payWindow}
            onOk={()=>{setPayWindow(false)}}
            width={"80%"}
            onCancel={()=>{setPayWindow(false)}}
            footer={[
                <Button key="back" danger ghost onClick={()=>{setPayWindow(false)}}>
                  Cancelar
                </Button>,
                <Button key="submit" type="primary" ghost onClick={payConcepts}>
                  Pagar Seleccionados
                </Button>
              ]}
            >
                <Row className="custom-header">
                    <Col xs={24} md={12}>
                        Concepto
                    </Col>
                    <Col xs={12} md={6}>
                        Vence
                    </Col>
                    <Col xs={12} md={6}>
                        Debe
                    </Col>
                </Row>
                {
                    selectedList.map((concept:Status, key:number)=>(
                        <Row className="custom-body">
                            <Col xs={24} md={12}>
                                    { concept.subject ? concept.concept.title+" - "+concept.subject.name  : concept.concept.title} { concept.quantity && concept.quantity > 1 ? "x"+concept.quantity : "" } { concept.concept.is_variable ? <sup title="El monto puede estar sujeto a cambios">(*)</sup> : '' }
                            </Col>
                            <Col xs={12} md={6}>
                                    { concept.limit_date ? format(new Date(concept.limit_date), 'dd/MM/yyyy') : ''}
                            </Col>
                            <Col xs={12} md={6} className="text-end">
                                $ {commaSeparator((concept.total_amount + concept.surcharges_amount - concept.subtotal - concept.discounts_amount).toFixed(2))}
                            </Col>
                        </Row>
                    ))
                }
                <Row className="custom-footer">
                    <Col xs={24} md={18}>
                        Total
                    </Col>
                    <Col xs={12} md={6} className="text-end">
                        $ {commaSeparator(getSumToTal().toFixed(2))}
                    </Col>
                </Row>

                {
                    checkPriceVariable( selectedList ) && (
                        <p className="mt-10">(*) El monto puede estar sujeto a cambios</p>
                    )
                }

                <Row>
                    <Form>
                        {config.show_amount_to_pay ? (
                            <Form.Item label="Ingrese la cantidad a pagar" className="mt-3" id={"concept-input"}>                    
                                <Input type="number" onChange={(e:React.ChangeEvent<HTMLInputElement>)=>onChangeAmount(e)}></Input>
                            </Form.Item>
                        ):(
                            <h4 className="mt-5">
                                <b>Cantidad a pagar:
                                <Col xs={24} md={{span: 24}}>
                                    $ {commaSeparator(getSumToTal().toFixed(2))}
                                </Col>
                                </b>
                            </h4>
                        )}
                        <Form.Item label="Escoge un método de pago">
                            <Radio.Group onChange={onChangeTypePayment}>
                                {
                                    configPayment.conekta_enabled && (
                                        <>
                                            <Radio value={'oxxo'}>Oxxo</Radio>
                                            <Radio value={'card/spei'}>Tarjeta/SPEI</Radio>
                                        </>
                                    )
                                }
                                {
                                  !config.show_amount_to_pay && (
                                    <Radio value={'banco'}>Banco</Radio>
                                  )  
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Form>
                </Row>
            </Modal>

            <Modal
                title='Pagos Registrados'
                visible={paymentsWindow}
                onOk={()=>{setPaymentsWindow(false)}}
                onCancel={()=>{setPaymentsWindow(false)}}
                width={"80%"}
                footer={[
                    <Button key="back" onClick={()=>{setPaymentsWindow(false)}} danger ghost>
                    Cerrar
                    </Button>
                ]}
            >
                <Table pagination={false} columns={registeredPaymentsColumns} dataSource={selectedConcept.payments} rowKey="_id"/>
            </Modal>
        </div>
    )
}

export default StatusStudent;